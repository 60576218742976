
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICampaign, IDateRange, IPopup } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN, ACTION_POPUP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import { lastNMonth, now } from '@/utils/datetime'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import DateRangeSelector from '@/components/common/ui/DateRangeSelector.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: { CLoading, DateRangeSelector, TestModeSelector },
  directives: { maska },
  emits: [],
})
export default class PopupList extends mixins(BaseFormMixin) {
  loading = true
  firstLoad = true
  selectedCampaignId = ''
  isTestMode = false
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  campaigns: ICampaign[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  get popups() {
    return cloneDeep(this.$store.getters.popups) || []
  }

  @Watch('campaignId', { immediate: true })
  async loadData() {
    if (!this.selectedAppId) {
      return
    }
    this.firstLoad = true

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }

    const cams = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: false,
    })

    this.campaigns = cams

    this.selectedCampaignId = this.campaignId
    await this.loadPopups()
    this.loading = false
    this.firstLoad = false
  }

  @Watch('isTestMode')
  async isTestModeChange() {
    this.loadPopups()
  }

  @Watch('selectedCampaignId')
  handleChangeCampaign() {
    if (this.firstLoad) {
      return
    }
    this.loadPopups()
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    this.loadPopups()
  }

  async loadPopups() {
    await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      campaign_id: this.selectedCampaignId,
      date_from: this.dateRange.from,
      date_to: this.dateRange.to,
    })
  }

  async updateStatusMode(item: IPopup, statusMode) {
    let mod = ACTION_POPUP.IS_ACTIVE
    if (statusMode) {
      mod = ACTION_POPUP.TEST_MODE
    }
    const isSuccess = await this.$store.dispatch(mod, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IPopup) {
    const item = this.popups.find((item) => item._id !== record._id && item.is_active === true)
    if (!item) {
      this.updateStatusMode(record, false)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          item.is_active = false
          this.updateStatusMode(item, false)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: IPopup) {
    const item = this.popups.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!item) {
      this.updateStatusMode(record, true)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          item.is_test_mode = false
          this.updateStatusMode(item, true)
        })

      record.is_test_mode = false
    }
  }

  onAdd() {
    this.goto('popup_setting', {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
      action_type: constant.ACTION_TYPE.ADD,
    })
  }

  onEdit(data: IPopup) {
    this.goto('popup_setting', {
      app_id: data.app_id,
      campaign_id: data.campaign_id,
      popup_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onCopy(data: IPopup) {
    this.goto('popup_setting', {
      app_id: data.app_id,
      campaign_id: data.campaign_id,
      popup_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }

  async onDelete(data: IPopup) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_POPUP.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }
}
